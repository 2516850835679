import Footer from '@/components/navs/Footer'
import TopNav from '@/components/navs/top-nav'
import DigitalMarketers from '@/components/page-specific/dashboard/home/digital-marketers'
import CompetitorIntelligenceSection from '@/components/page-specific/index/11x-benefits'
import Companies from '@/components/page-specific/index/companies'
import { CTA } from '@/components/page-specific/index/cta'
import FAQSection from '@/components/page-specific/index/faq'
import { HeroCenter } from '@/components/page-specific/index/hero'
import { Metrics } from '@/components/page-specific/index/metrics'
import { SecurityDetails } from '@/components/page-specific/index/security'
import { KeyFeatures } from '@/components/page-specific/index/tools'
import { AnimatePresence } from 'framer-motion'
import { Activity } from 'lucide-react'
import { NextSeo } from 'next-seo'
import { PostHogFeature, useFeatureFlagVariantKey, usePostHog } from 'posthog-js/react'

export default function Home() {
  const genericVsDigitalMarketers = useFeatureFlagVariantKey('generic-vs-digital-marketer')
  const posthog = usePostHog()

  // posthog.featureFlags.override({ 'generic-vs-digital-marketer': 'digital-marketer' })

  if (process.env.NEXT_PUBLIC_MAINTENANCE_MODE === 'true') {
    return (
      <div className="min-h-screen flex flex-col items-center justify-center p-6 bg-zinc-50 ">
        <Activity className=" w-24 h-24 text-zinc-500  mb-8" />
        <h1 className="text-4xl font-bold text-center text-zinc-800  mb-4">
          We're down for maintenance
        </h1>
        <p className="text-lg text-center text-zinc-600  mb-8">
          We're currently updating our platform with some cool new features. We'll be back soon!
        </p>
        <div className="w-full max-w-md bg-zinc-200 rounded">
          <div
            className="h-2 bg-primary rounded"
            style={{
              width: '90%',
            }}
          />
        </div>
        <p className="text-xs text-center text-zinc-500 mt-4">Installation progress: 90%</p>
      </div>
    )
  }

  if (genericVsDigitalMarketers === 'digital-marketer') {
    return (
      <PostHogFeature flag="generic-vs-digital-marketer" match={genericVsDigitalMarketers}>
        <DigitalMarketers />
      </PostHogFeature>
    )
  } else {
    return (
      <>
        <NextSeo
          title="Opinly - Automated Competitor Analysis"
          description="Opinly automates competitor analysis and real-time data tracking of competitors. Gain strategic insights with our comprehensive competitive analysis and real-time data tracking."
          themeColor="#ffffff"
        />
        <AnimatePresence>
          <div className="lg:mx-24 lg:h-7 lg:border-x lg:border-zinc-200" />
          <div className="lg:border-x lg:border-b lg:border-zinc-200" />
          <div className="lg:mx-24 lg:border-x lg:border-zinc-200">
            <div className="p-2">
              <TopNav />
            </div>
            <HeroCenter />
            <Companies />
            <CompetitorIntelligenceSection />
            <Metrics />
            <KeyFeatures />
            <SecurityDetails />
            <FAQSection />
            <CTA />
            <Footer />
          </div>
        </AnimatePresence>
      </>
    )
  }
}
