import { AnimatedBeam } from '@/components/magicui/animated-beam'
import Marquee from '@/components/magicui/marquee'
import Footer from '@/components/navs/Footer'
import TopNav from '@/components/navs/top-nav'
import { PlaceholdersAndVanishInput } from '@/components/ui/aceternity/placeholders-and-vanish-input'
import { Button } from '@/components/ui/button'
import { cn } from '@/lib/utils'
import { AnimatePresence } from 'framer-motion'
import { Activity, Check, Users } from 'lucide-react'
import { NextSeo } from 'next-seo'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { forwardRef, useRef } from 'react'
import { BiLogoBing } from 'rocketicons/bi'
import { BsGoogle, BsInstagram } from 'rocketicons/bs'
import { CgFacebook } from 'rocketicons/cg'
import { MdAdsClick } from 'rocketicons/md'

const reviews = [
  {
    name: 'Asser',
    role: 'SEO Manager',
    body: "Opinly's competitor backlink analysis were proper game-changer. We found over 20 missing backlink chances that competitors had, and now we're out there building those connections.",
    img: '/files/testimonials/1.webp',
  },
  {
    name: 'Harry',
    role: 'Digital Marketing Lead',
    body: 'The keyword gap analysis in Opinly showed us hundreds of long-tail keywords our competitors ranking for that we completely missed. Our organic traffic gone up 23% since using those insights.',
    img: '/files/testimonials/6.webp',
  },
  {
    name: 'Daniel',
    role: 'Content Strategist',
    body: 'Seeing our position changes week by week across 500+ keywords means we can act fast if we drop in rankings.',
    img: '/files/testimonials/5.webp',
  },
  {
    name: 'Imran',
    role: 'Email Marketing Manager',
    body: 'The subject line analysis of competitor campaigns gave us great insights. We now A/B test emotional vs. practical subject lines based on what works in our industry.',
    img: '/files/testimonials/4.webp',
  },
  {
    name: 'Daiyu',
    role: 'Marketing Director',
    body: 'Love how Opinly brings together metrics from our email campaigns across departments. The performance scores make it simple to focus on the messaging tweaks that get us the biggest engagement boost across our teams.',
    img: '/files/testimonials/2.webp',
  },
  {
    name: 'Tolga',
    role: 'Market Research Analyst',
    body: "Opinly's employment tracking helped us spot hiring trends in our market. We could see which competitors were expanding which departments, giving us early signals about their strategic moves.",
    img: '/files/testimonials/3.webp',
  },
]

const firstRow = reviews.slice(0, reviews.length / 2)
const secondRow = reviews.slice(reviews.length / 2)

const ReviewCard = ({
  img,
  name,
  role,
  body,
}: {
  img: string
  name: string
  role: string
  body: string
}) => {
  return (
    <figure
      className={cn(
        'relative w-96  overflow-hidden rounded-xl border p-4',
        'border-gray-950/[.1] bg-gray-950/[.01] '
      )}
    >
      <div className="flex flex-row items-center gap-2">
        <img className="rounded-full" width="32" height="32" alt="" src={img} />
        <div className="flex flex-col">
          <figcaption className="text-sm font-medium dark:text-white">{name}</figcaption>
          <p className="text-xs font-medium dark:text-white/40">{role}</p>
        </div>
      </div>
      <blockquote className="mt-2 text-sm">{body}</blockquote>
    </figure>
  )
}

export function MarqueeDemo() {
  return (
    <div className="px-8">
      <div className="relative flex w-full flex-col items-center justify-center overflow-hidden">
        <Marquee pauseOnHover className="[--duration:20s]">
          {firstRow.map((review) => (
            <ReviewCard key={review.role} {...review} />
          ))}
        </Marquee>
        <Marquee reverse pauseOnHover className="[--duration:20s]">
          {secondRow.map((review) => (
            <ReviewCard key={review.role} {...review} />
          ))}
        </Marquee>
        <div className="pointer-events-none absolute inset-y-0 left-0 w-1/3 bg-gradient-to-r from-white dark:from-background"></div>
        <div className="pointer-events-none absolute inset-y-0 right-0 w-1/3 bg-gradient-to-l from-white dark:from-background"></div>
      </div>
    </div>
  )
}

const Circle = forwardRef<HTMLDivElement, { className?: string; children?: React.ReactNode }>(
  ({ className, children }, ref) => {
    return (
      <div
        ref={ref}
        className={cn(
          'ml-4 flex size-12 z-10 items-center justify-center rounded-full border-2 border-border border-zinc-700 bg-zinc-800 p-3 shadow-[0_0_20px_-12px_rgba(0,0,0,0.8)]',
          className
        )}
      >
        {children}
      </div>
    )
  }
)

Circle.displayName = 'Circle'

export function AnimatedBeamMultipleOutputDemo({ className }: { className?: string }) {
  const containerRef = useRef<HTMLDivElement>(null)
  const div1Ref = useRef<HTMLDivElement>(null)
  const div2Ref = useRef<HTMLDivElement>(null)
  const div3Ref = useRef<HTMLDivElement>(null)
  const div4Ref = useRef<HTMLDivElement>(null)
  const div5Ref = useRef<HTMLDivElement>(null)
  const div6Ref = useRef<HTMLDivElement>(null)
  const div7Ref = useRef<HTMLDivElement>(null)

  return (
    <div className="mx-4 md:mx-4 md:mx-8">
      <div
        className={cn(
          'my-32 relative w-full flex flex-col items-center justify-center overflow-hidden p-4 md:p-10 bg-zinc-900 rounded-xl shadow-2xl',
          className
        )}
        ref={containerRef}
      >
        <div className="space-y-2 max-w-3xl text-center mb-8">
          <h2 className="text-3xl md:text-5xl text-zinc-100 font-medium">
            Create one source of truth
          </h2>
          <h3 className="text-base md:text-lg text-zinc-200">
            Opinly integrates your data from 150+ platforms and makes it analysis-ready in your
            go-to reporting and analytics tools.
          </h3>
        </div>
        <div className="flex size-full flex-row items-stretch justify-between gap-10 max-w-lg py-8">
          <div className="flex flex-col justify-center gap-2">
            <Circle ref={div1Ref}>
              <Icons.ads />
            </Circle>
            <Circle ref={div2Ref}>
              <Icons.facebook />
            </Circle>
            <Circle ref={div3Ref}>
              <Icons.bing />
            </Circle>
            <Circle ref={div4Ref}>
              <Icons.instagram />
            </Circle>
            <Circle ref={div5Ref}>
              <Icons.google />
            </Circle>
            <div
              className="flex items-center bg-zinc-800 rounded-lg px-2 border border-zinc-700 border-[2px] mr-12 z-10"
              ref={div3Ref}
            >
              <Icons.others />
            </div>
          </div>
          <div className="flex flex-col justify-center">
            <Circle ref={div6Ref} className="size-16">
              <Image src="/files/logos/small-icon.webp" alt="Opinly" width={100} height={100} />
            </Circle>
          </div>
          <div className="flex flex-col justify-center">
            <Circle ref={div7Ref}>
              <Icons.user />
            </Circle>
          </div>
        </div>

        <AnimatedBeam containerRef={containerRef} fromRef={div1Ref} toRef={div6Ref} />
        <AnimatedBeam containerRef={containerRef} fromRef={div2Ref} toRef={div6Ref} />
        <AnimatedBeam containerRef={containerRef} fromRef={div3Ref} toRef={div6Ref} />
        <AnimatedBeam containerRef={containerRef} fromRef={div4Ref} toRef={div6Ref} />
        <AnimatedBeam containerRef={containerRef} fromRef={div5Ref} toRef={div6Ref} />
        <AnimatedBeam containerRef={containerRef} fromRef={div6Ref} toRef={div7Ref} />
      </div>
    </div>
  )
}

const Icons = {
  google: () => <BsGoogle className="icon-orange-500" />,
  instagram: () => <BsInstagram className="icon-orange-500" />,
  ads: () => <MdAdsClick className="icon-orange-500" />,
  bing: () => <BiLogoBing className="icon-orange-500" />,
  others: () => <div className="text-orange-500 text-xs font-bold py-1 z-20">Others</div>,
  facebook: () => <CgFacebook className="icon-orange-500" />,
  zapier: () => (
    <svg
      width="105"
      height="28"
      viewBox="0 0 244 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M57.1877 45.2253L57.1534 45.1166L78.809 25.2914V15.7391H44.0663V25.2914H64.8181L64.8524 25.3829L43.4084 45.2253V54.7775H79.1579V45.2253H57.1877Z"
        fill="#201515"
      />
      <path
        d="M100.487 14.8297C96.4797 14.8297 93.2136 15.434 90.6892 16.6429C88.3376 17.6963 86.3568 19.4321 85.0036 21.6249C83.7091 23.8321 82.8962 26.2883 82.6184 28.832L93.1602 30.3135C93.5415 28.0674 94.3042 26.4754 95.4482 25.5373C96.7486 24.5562 98.3511 24.0605 99.9783 24.136C102.118 24.136 103.67 24.7079 104.634 25.8519C105.59 26.9959 106.076 28.5803 106.076 30.6681V31.7091H95.9401C90.7807 31.7091 87.0742 32.8531 84.8206 35.1411C82.5669 37.429 81.442 40.4492 81.4458 44.2014C81.4458 48.0452 82.5707 50.9052 84.8206 52.7813C87.0704 54.6574 89.8999 55.5897 93.3089 55.5783C97.5379 55.5783 100.791 54.1235 103.067 51.214C104.412 49.426 105.372 47.3793 105.887 45.2024H106.27L107.723 54.7546H117.275V30.5651C117.275 25.5659 115.958 21.6936 113.323 18.948C110.688 16.2024 106.409 14.8297 100.487 14.8297ZM103.828 44.6475C102.312 45.9116 100.327 46.5408 97.8562 46.5408C95.8199 46.5408 94.4052 46.1843 93.6121 45.4712C93.2256 45.1338 92.9182 44.7155 92.7116 44.246C92.505 43.7764 92.4043 43.2671 92.4166 42.7543C92.3941 42.2706 92.4702 41.7874 92.6403 41.3341C92.8104 40.8808 93.071 40.4668 93.4062 40.1174C93.7687 39.7774 94.1964 39.5145 94.6633 39.3444C95.1303 39.1743 95.6269 39.1006 96.1231 39.1278H106.093V39.7856C106.113 40.7154 105.919 41.6374 105.527 42.4804C105.134 43.3234 104.553 44.0649 103.828 44.6475Z"
        fill="#201515"
      />
      <path d="M175.035 15.7391H163.75V54.7833H175.035V15.7391Z" fill="#201515" />
      <path
        d="M241.666 15.7391C238.478 15.7391 235.965 16.864 234.127 19.1139C232.808 20.7307 231.805 23.1197 231.119 26.2809H230.787L229.311 15.7391H219.673V54.7775H230.959V34.7578C230.959 32.2335 231.55 30.2982 232.732 28.9521C233.914 27.606 236.095 26.933 239.275 26.933H243.559V15.7391H241.666Z"
        fill="#201515"
      />
      <path
        d="M208.473 17.0147C205.839 15.4474 202.515 14.6657 198.504 14.6695C192.189 14.6695 187.247 16.4675 183.678 20.0634C180.108 23.6593 178.324 28.6166 178.324 34.9352C178.233 38.7553 179.067 42.5407 180.755 45.9689C182.3 49.0238 184.706 51.5592 187.676 53.2618C190.665 54.9892 194.221 55.8548 198.344 55.8586C201.909 55.8586 204.887 55.3095 207.278 54.2113C209.526 53.225 211.483 51.6791 212.964 49.7211C214.373 47.7991 215.42 45.6359 216.052 43.3377L206.329 40.615C205.919 42.1094 205.131 43.4728 204.041 44.5732C202.942 45.6714 201.102 46.2206 198.521 46.2206C195.451 46.2206 193.163 45.3416 191.657 43.5837C190.564 42.3139 189.878 40.5006 189.575 38.1498H216.201C216.31 37.0515 216.367 36.1306 216.367 35.387V32.9561C216.431 29.6903 215.757 26.4522 214.394 23.4839C213.118 20.7799 211.054 18.5248 208.473 17.0147ZM198.178 23.9758C202.754 23.9758 205.348 26.2275 205.962 30.731H189.775C190.032 29.2284 190.655 27.8121 191.588 26.607C193.072 24.8491 195.268 23.972 198.178 23.9758Z"
        fill="#201515"
      />
      <path
        d="M169.515 0.00366253C168.666 -0.0252113 167.82 0.116874 167.027 0.421484C166.234 0.726094 165.511 1.187 164.899 1.77682C164.297 2.3723 163.824 3.08658 163.512 3.87431C163.2 4.66204 163.055 5.50601 163.086 6.35275C163.056 7.20497 163.201 8.05433 163.514 8.84781C163.826 9.64129 164.299 10.3619 164.902 10.9646C165.505 11.5673 166.226 12.0392 167.02 12.3509C167.814 12.6626 168.663 12.8074 169.515 12.7762C170.362 12.8082 171.206 12.6635 171.994 12.3514C172.782 12.0392 173.496 11.5664 174.091 10.963C174.682 10.3534 175.142 9.63077 175.446 8.83849C175.75 8.04621 175.89 7.20067 175.859 6.35275C175.898 5.50985 175.761 4.66806 175.456 3.88115C175.151 3.09424 174.686 2.37951 174.09 1.78258C173.493 1.18565 172.779 0.719644 171.992 0.414327C171.206 0.109011 170.364 -0.0288946 169.521 0.00938803L169.515 0.00366253Z"
        fill="#201515"
      />
      <path
        d="M146.201 14.6695C142.357 14.6695 139.268 15.8764 136.935 18.2902C135.207 20.0786 133.939 22.7479 133.131 26.2981H132.771L131.295 15.7563H121.657V66H132.942V45.3054H133.354C133.698 46.6852 134.181 48.0267 134.795 49.3093C135.75 51.3986 137.316 53.1496 139.286 54.3314C141.328 55.446 143.629 56.0005 145.955 55.9387C150.68 55.9387 154.277 54.0988 156.748 50.419C159.219 46.7392 160.455 41.6046 160.455 35.0153C160.455 28.6509 159.259 23.6689 156.869 20.0691C154.478 16.4694 150.922 14.6695 146.201 14.6695ZM147.345 42.9602C146.029 44.8668 143.97 45.8201 141.167 45.8201C140.012 45.8735 138.86 45.6507 137.808 45.1703C136.755 44.6898 135.832 43.9656 135.116 43.0574C133.655 41.2233 132.927 38.7122 132.931 35.5243V34.7807C132.931 31.5432 133.659 29.0646 135.116 27.3448C136.572 25.625 138.59 24.7747 141.167 24.7937C144.02 24.7937 146.092 25.6994 147.385 27.5107C148.678 29.322 149.324 31.8483 149.324 35.0896C149.332 38.4414 148.676 41.065 147.356 42.9602H147.345Z"
        fill="#201515"
      />
      <path d="M39.0441 45.2253H0V54.789H39.0441V45.2253Z" fill="#FF4F00" />
    </svg>
  ),
  user: () => <Users className="text-orange-500" />,
}

const companies = [
  { href: 'https://www.steppay.kr', src: '/files/companies/steppay.webp', name: 'Steppay' },
  { href: 'https://www.bosch.com/', src: '/files/companies/bosch.webp', name: 'Bosch' },
  { href: 'https://www.sanalabs.com/', src: '/files/companies/sanalabs.webp', name: 'Sana Labs' },
  { href: 'https://up.codes/', src: '/files/companies/upcodes.webp', name: 'UpCodes' },
  { href: 'https://www.zeni.ai/', src: '/files/companies/zeni.webp', name: 'Zeni AI' },
  {
    href: 'https://www.paramountgroup.com/',
    src: '/files/companies/paramountgroup.webp',
    name: 'Paramount Group',
  },
  {
    href: 'https://www.lemonade.com/',
    src: '/files/companies/lemonade.webp',
    name: 'Lemonade',
  },
]

const CompanyCard = ({ href, src, name }: { href: string; src: string; name: string }) => {
  return (
    <Link
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      className={cn(
        'relative w-32 cursor-pointer overflow-hidden rounded-xl p-2',
        'bg-zinc-950/[.01] hover:bg-red-950/[.05]',
        ''
      )}
    >
      <div className="flex flex-col items-center gap-2">
        <Image
          className="h-12 w-auto object-contain"
          src={src}
          alt={`${name} logo`}
          width={200}
          height={80}
          priority
        />
      </div>
    </Link>
  )
}

export default function DigitalMarketers() {
  const router = useRouter()

  if (process.env.NEXT_PUBLIC_MAINTENANCE_MODE === 'true') {
    return (
      <div className="min-h-screen flex flex-col items-center justify-center p-6 bg-zinc-50 ">
        <Activity className=" w-24 h-24 text-zinc-500  mb-8" />
        <h1 className="text-4xl font-bold text-center text-zinc-800  mb-4">
          We're down for maintenance router,{' '}
        </h1>
        <p className="text-lg text-center text-zinc-600  mb-8">
          We're currently updating our platform with some cool new features. We'll be back soon!
        </p>
        <div className="w-full max-w-md bg-zinc-200 rounded">
          <div
            className="h-2 bg-primary rounded"
            style={{
              width: '90%',
            }}
          />
        </div>
        <p className="text-xs text-center text-zinc-500 mt-4">Installation progress: 90%</p>
      </div>
    )
  }

  interface MetricCardProps {
    value: string
    label: string
    subtext: string
  }

  const MetricCard: React.FC<MetricCardProps> = ({ value, label, subtext }) => {
    return (
      <div className="flex flex-col items-center p-4">
        <div className="text-5xl font-bold mb-2 text-center text-zinc-700">{value}</div>
        <div className="text-lg font-semibold mb-1 text-center  text-zinc-700">{label}</div>
        <div className="text-sm text-zinc-600 text-center">{subtext}</div>
      </div>
    )
  }

  const metrics = [
    {
      value: '3.5x',
      label: 'Higher Open Rates',
      subtext: 'with AI subject optimization',
    },
    {
      value: '62%',
      label: 'More Click-Throughs',
      subtext: 'from email campaigns',
    },
    {
      value: '83%',
      label: 'Backlink Growth',
      subtext: 'from competitor insights',
    },
    {
      value: '2.8x',
      label: 'Organic Traffic',
      subtext: 'increase in 6 months',
    },
  ]
  return (
    <>
      <NextSeo
        title="Automated Competitor Analysis | Opinly"
        description="Opinly automates competitor analysis and real-time data tracking of competitors. Gain strategic insights with our comprehensive competitive analysis and real-time data tracking."
        themeColor="#ffffff"
      />
      <AnimatePresence>
        <div className="lg:mx-32 lg:h-7 lg:border-x lg:border-zinc-200" />
        <div className="lg:border-x lg:border-b lg:border-zinc-200" />
        <div className="lg:mx-32 lg:border-x lg:border-zinc-200 space-y-10">
          <div className="p-2 md:pt-12 md:pt-40">
            <TopNav />
          </div>

          <section className="pt-20 md:pt-0 flex items-center p-2 lg:p-0 grid gap-16 md:gap-10 lg:grid-cols-2 w-11/12 lg:w-11/12 mx-auto flex justify-between">
            <div className="text-left lg:text-left space-y-8">
              <h1 className="pb-1 text-4xl md:text-4xl font-bold lg:text-5xl">
                <span className="text-zinc-800">Maximize marketing with</span>{' '}
                <span className="text-orange-500">competitive intelligence</span>
              </h1>
              <p className="max-w-screen-md font-normal text-md text-zinc-800 font-medium">
                Turn scattered data into winning strategies.{' '}
                <span className="hidden md:inline">
                  <br />
                  Get insights across all channels –{' '}
                </span>
                <span className="font-bold">including your competitors' moves</span>.
              </p>
              <div className="flex flex-col md:hidden md:grid md:grid-cols-2 space-y-2 w-fit font-semibold text-zinc-600">
                <div className="flex items-center space-x-2">
                  <div className="flex items-center space-x-2 w-fit">
                    <Check className="h-5 w-5" />
                    <p className="text-zinc-700">SEO</p>
                  </div>
                  <div className="flex items-center space-x-2 w-fit ">
                    <Check className="h-5 w-5" />
                    <p className="text-zinc-700">Landing pages</p>
                  </div>
                </div>
                <div className="flex items-center space-x-2">
                  <div className="flex items-center space-x-2 w-fit">
                    <Check className="h-5 w-5" />
                    <p className="text-zinc-700">Emails</p>
                  </div>
                  <div className="flex items-center space-x-2 w-fit">
                    <Check className="h-5 w-5" />
                    <p className="text-zinc-700">Employees</p>
                  </div>
                </div>

                <div className="flex items-center space-x-2 w-fit col-span-2">
                  <Check className="h-5 w-5" />
                  <p className="text-zinc-700">
                    Social Media <span className="font-normal">(coming soon!)</span>
                  </p>
                </div>
              </div>
              <div className="md:hidden">
                <Link
                  href="/auth/signup"
                  className="text-sm bg-zinc-900 font-bold text-white hover:bg-zinc-200 px-8 py-2 rounded-sm transition-colors"
                >
                  <span>Get started</span>
                </Link>
              </div>
            </div>

            <div className="hidden md:flex rounded-2xl h-fit border py-12 w-full px-2 lg:p-12 flex-col space-y-4">
              <span className="text-lg font-semibold text-center md:text-left">
                Enter your company
              </span>
              <div className="w-full">
                <PlaceholdersAndVanishInput
                  placeholders={[
                    '"Gymshark"',
                    '"Apple"',
                    '"Salesforce"',
                    '"Nike"',
                    '"Microsoft"',
                    '"Chipotle"',
                    '"TypeForm"',
                  ]}
                  onChange={() => {}}
                  onSubmit={() => {
                    router.push('/auth/signup')
                  }}
                />

                <p className="text-zinc-500 text-xs mt-2 text-center w-full">and press Enter ⏎</p>
              </div>
            </div>
          </section>

          <div className="pt-10 md:pt-32 bg-white pb-6 mx-4 md:mx-8 rounded-xl">
            <div className="overflow-hidden max-w-4xl mx-auto space-y-4 w-full ">
              <p className="w-full text-center font-semibold text-zinc-700">
                Fueling insights for 1000+ companies in 32 countries
              </p>
              <div className="relative flex w-full flex-col items-center justify-center overflow-hidden rounded-lg bg- md:shadow-xl">
                <Marquee pauseOnHover className="[--duration:20s]">
                  {companies.map((company) => (
                    <CompanyCard key={company.src} {...company} />
                  ))}
                </Marquee>

                <div className="pointer-events-none absolute inset-y-0 left-0 w-1/3 bg-gradient-to-r from-white"></div>
                <div className="pointer-events-none absolute inset-y-0 right-0 w-1/3 bg-gradient-to-l from-white"></div>
              </div>
            </div>
          </div>

          <MarqueeDemo />

          <div className="sm:hidden flex flex-col items-center justify-center space-y-6 pt-16 pb-8 px-4">
            <h2 className="font-bold text-3xl text-zinc-800 ">
              Discover more ways to grow your brand
              <span className="text-orange-500 text-5xl">.</span>
            </h2>

            <Link href="/auth/signup" className="w-full">
              <Button
                analyticsKey="home_signup_cta"
                analyticsProperties={{ section: 'public_site' }}
                className="bg-zinc-900 hover:bg-zinc-800 text-white font-semibold px-8 py-2 w-full text-lg"
              >
                Register{' '}
              </Button>
            </Link>
          </div>

          <AnimatedBeamMultipleOutputDemo />

          <div className="mt-32 mx-4 md:mx-8 ">
            <div className="mb-5 space-y-1">
              <h2 className="text-center text-4xl font-bold text-zinc-800">
                Opinly will boost your results
              </h2>
              <p className="text-center text-zinc-500 text-xl font-semibold">
                in the first <span className="font-bold text-orange-500">6 months</span>
              </p>
            </div>
            <div className="mb-8 bg-gradient-to-r from-orange-200/70 to-orange-500/30 p-6 rounded-lg shadow-lg">
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                {metrics.map((metric, index) => (
                  <MetricCard key={index} {...metric} />
                ))}
              </div>
            </div>
          </div>

          <div className="hidden md:block pt-10">
            <div className="px-24 mx-auto mb-12 rounded-lg sm:mb-16 sm:mt-20 md:mb-20 dark:border-transparent">
              <div className="space-y-1 rounded-lg border bg-[--ui-bg] p-1 backdrop-blur-2xl shadow-xl shadow-gray-950/5">
                <div className="flex items-center justify-between px-3">
                  <div className="flex gap-1.5">
                    <div
                      aria-hidden
                      className="size-2 rounded-full border border-gray-300 bg-gray-200/50 dark:border-white/5 dark:bg-white/10"
                    ></div>
                    <div
                      aria-hidden
                      className="size-2 rounded-full border border-gray-300 bg-gray-200/50 dark:border-white/5 dark:bg-white/10"
                    ></div>
                    <div
                      aria-hidden
                      className="size-2 rounded-full border border-gray-300 bg-gray-200/50 dark:border-white/5 dark:bg-white/10"
                    ></div>
                  </div>
                  <div
                    data-rounded="large"
                    className="relative flex h-6 w-56 items-center justify-center gap-4 rounded-[--card-radius] bg-gray-200/50 px-3 text-xs text-[--caption-text-color] dark:bg-[--ui-bg]"
                  >
                    opinly.ai/dashboard
                  </div>
                  {/* <Plus aria-hidden className="size-4 text-[--caption-text-color]" /> */}
                  <div />
                </div>
                <img
                  className="max-w-[60] max-h-[120vh] rounded-[calc(var(--card-radius)-5px)] border dark:hidden" //aspect-[4/3]
                  src="/files/landing/demo.webp"
                  alt=""
                />
                <img
                  className="hidden  rounded-[calc(var(--card-radius)-5px)] border dark:block" //aspect-[4/3]
                  src="/images/showcase/card-layout-dark.webp"
                  alt=""
                />
              </div>
            </div>
          </div>

          <div className="py-14">
            <div className="bg-white px-8">
              <h2 className="text-2xl font-bold text-gray-900 mb-6">What's the ROI of Opinly?</h2>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                <div className="p-6 border rounded-lg shadow-sm">
                  <div className="flex items-start mb-4 flex items-center space-x-2">
                    <div className="rounded-lg bg-orange-500/10 p-1">
                      <Check className="text-orange-500" />
                    </div>
                    <h3 className="text-lg font-semibold text-gray-800">
                      Improve return on advertisement
                    </h3>
                  </div>
                  <p className="text-gray-600">
                    Transform campaign data into actionable insights instantly. Our automated
                    reports help you optimize campaigns in real-time, delivering superior ROI on
                    every advertising dollar spent.
                  </p>
                </div>
                <div className="p-6 border rounded-lg shadow-sm">
                  <div className="flex items-start mb-4 flex items-center space-x-2">
                    <div className="rounded-lg bg-orange-500/10 p-1">
                      <Check className="text-orange-500" />
                    </div>
                    <h3 className="text-lg font-semibold text-gray-800">
                      80% less expensive to maintain
                    </h3>
                  </div>
                  <p className="text-gray-600">
                    Eliminate expensive custom development. Our plug-and-play platform costs just a
                    fraction of traditional integration solutions, with zero maintenance overhead
                    for your team.
                  </p>
                </div>
                <div className="p-6 border rounded-lg shadow-sm">
                  <div className="flex items-start mb-4 flex items-center space-x-2">
                    <div className="rounded-lg bg-orange-500/10 p-1">
                      <Check className="text-orange-500" />
                    </div>
                    <h3 className="text-lg font-semibold text-gray-800">
                      Reduce reporting time by 50%
                    </h3>
                  </div>
                  <p className="text-gray-600">
                    Free your team from manual data work. Automated reporting lets you focus on
                    strategic analysis and optimization, turning insights into action twice as fast.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <section className="text-white bg-zinc-900 py-16 md:mx-4 md:mx-8 px-4 md:px-0 md:rounded-xl">
            <div className="w-full md:w-5/6 mx-auto md:flex items-center ">
              <div className="md:w-2/3">
                <p className="text-2xl font-bold mb-4">Stay Ahead in the Market with Opinly</p>
                <p className="text-lg mt-4">
                  Elevate your product's position or craft a unique value proposition with Opinly's
                  competitor analysis software, providing the insights and tools needed to succeed
                  in a competitive market.
                </p>
              </div>
              <div className="ml-auto mt-8 md:mt-0">
                <Button
                  analyticsKey="stay_ahead_cta"
                  className=" text-md font-semibold"
                  variant={'white'}
                  size={'lg'}
                  analyticsProperties={{
                    section: 'public_site',
                  }}
                >
                  <Link href="/auth/signup" className="hidden md:block">
                    GET STARTED
                  </Link>
                  <Link href="/auth/signup" className="md:hidden">
                    Register
                  </Link>
                </Button>
              </div>
            </div>
          </section>
          <Footer />
        </div>
      </AnimatePresence>
    </>
  )
}
